import React, { createContext } from 'react';

import { CartProvider } from '../../context/CartContext';

import Layout from '../../layouts/es.jsx';
import { ShoppingCartSlideout } from '@components/pages/shop';
import { CartContext } from '../../context/CartContext';

export const UserStateContext = createContext(null);

const LayoutStore = ({ children, pageId }) => {
  return (
    <CartProvider>
      <CartContext.Consumer>
        {({ showShoppingCart, setShowShoppingCart }) => (
          <>
            <Layout pageId={pageId}>{children}</Layout>
            {showShoppingCart && (
              <ShoppingCartSlideout setShowShoppingCart={setShowShoppingCart} />
            )}
          </>
        )}
      </CartContext.Consumer>
    </CartProvider>
  );
};

export default LayoutStore;
